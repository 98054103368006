import firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/functions";



const config = {
    apiKey: "AIzaSyDpXrARzUWpSqP5jCLLTuKlTrQ9oiGpWrc",
    authDomain: "linetweet-fielmann-prod-7a33f.firebaseapp.com",
    databaseURL: "https://linetweet-fielmann-prod-7a33f.firebaseio.com",
    projectId: "linetweet-fielmann-prod-7a33f",
    storageBucket: "linetweet-fielmann-prod-7a33f.appspot.com",
    messagingSenderId: "121149828358",
    appId: "1:121149828358:web:82a5297fc6f3ede0298287",
    measurementId: "G-VKHVSHBQQM"
};

// const config = {
//     apiKey: "AIzaSyDUmNL0URS0VbiQM4VhZ7CoFapvW6N1j8U",
//     authDomain: "linetweet-fielmann-prod-7a33f.firebaseapp.com",
//     databaseURL: "https://linetweet-fielmann-prod-7a33f.firebaseio.com",
//     projectId: "linetweet-fielmann-prod-7a33f",
//     storageBucket: "linetweet-fielmann-prod-7a33f.appspot.com",
//     messagingSenderId: "121149828358",
//     appId: "1:121149828358:web:8e6d5d50e81eeef0298287",
//     measurementId: "G-4TLKFE3WPL"
// };


// Initialize Firebase
const app = firebase.initializeApp(config);
export default app;

export const fn = app.functions('europe-west1');
// fn.useFunctionsEmulator('http://localhost:5001');


