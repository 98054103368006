import React, {useCallback, useEffect, useState} from 'react';
// import { Button, Container, CssBaseline, TextField} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles, ThemeProvider} from '@material-ui/core/styles';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {fn} from './utils/Firebase';
import {BrowserRouter as Router, Route, Switch, useParams} from "react-router-dom";
import logo from './assets/logo.png';
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
    paper: {
        // marginTop: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#1a1a1a',
        },
    },
});

const schema = yup.object().shape({
    password: yup
        .string()
        .required('Das ist ein Pflichtfeld')
        .matches(
            /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/,
            'Das Passwort muss mindestens 6 Zeichen mit mindestens einer Zahl, einem Groß- und einem Kleinbuchstaben enthalten.',
        )
        .label('Neues Passwort'),
    repeat_password: yup
        .string()
        .required('Das ist ein Pflichtfeld')
        .oneOf([yup.ref('password')], 'Die Passwörter müssen übereinstimmen')
        .label('Passwort Eingabe'),
});


function ResetPassword() {
    const classes = useStyles();

    const [loading, setLoading] = useState(true);

    const [saving, setSaving] = useState(false);
    const [done, setDone] = useState(false);
    const [error, setError] = useState('');
    const [notFound, setNotFound] = useState(false);


    let {token} = useParams();

    useEffect(() => {
        const checkToken = fn.httpsCallable('checkToken');
        checkToken({token}).then(({data}) => {
            if (data.error) {
                // setError('Link not found or was already used')
                setNotFound(true);
            }
            if (data.status) {

            }
            setLoading(false);


        }).catch((e) => {
            setError('Undefined error');
            setLoading(false);
        })
    }, [token])


    const {register, handleSubmit, errors} = useForm({
        resolver: yupResolver(schema),
    });


    const submit = useCallback(async ({password}) => {
        setSaving(true);
        try {
            const resetPassword = fn.httpsCallable('resetPassword');
            resetPassword({token, password}).then(({data}) => {
                if (data.error) {
                    // setError('Link not found or was already used')
                    setError('Undefined error');
                }
                if (data.status) {
                    setDone(true);
                }
                setSaving(false);


            }).catch((e) => {
                setError('Undefined error');
                setSaving(false);
            })
        } catch (e) {
            console.log(e);
        }

    }, [token]);

    if (loading) {

        return <ThemeProvider theme={theme}>
            <Box textAlign={'center'} mt={5}>
            <CircularProgress/>
        </Box>
        </ThemeProvider>;
    }

    if (notFound) {
        return <Container maxWidth={'xs'}>
            <Box m={4}>
                <Alert severity="error">Der Link ist ungültig oder wurde bereits verwendet.</Alert>
            </Box>
        </Container>
    }


    if (done) {
        return <Container maxWidth={'xs'}>
            <Box m={4}>
                <Alert severity="success">Das neue Passwort wurde erfolgreich eingerichtet.</Alert>
            </Box>
        </Container>
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <Container maxWidth="sm" style={{
                    backgroundColor: 'white',

                }}>
                    <Box my={3} pt={3} textAlign={'center'}>
                        <img src={logo} alt={'Fielmann'}/>
                    </Box>
                    <CssBaseline/>


                    <div style={{lineHeight: '24px'}}>
                        <AlertTitle>Sehr geehrte Damen und Herren,</AlertTitle>
                        nutzen Sie diese Seite um sich ein Passwort für Ihren Zugang zur Digitalen Warteliste
                        einzurichten.<br/>
                        Achten Sie bei der Anlage auf ein starkes Passwort unter Verwendung von Groß- und
                        Kleinbuchstaben, Zahlen und Sonderzeichen.
                    </div>
                    <CssBaseline/>
                    <div className={classes.paper}>
                        <Box pt={1} width={'100%'}>
                            {!loading && error && <Alert severity="error">{error}</Alert>}
                            <form noValidate onSubmit={handleSubmit(submit)}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    name="password"
                                    label="Neues Passwort"
                                    id="password"
                                    type={'password'}
                                    defaultValue={''}
                                    inputRef={register}
                                    error={!!errors.password}
                                    helperText={errors.password && errors.password.message}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    name="repeat_password"
                                    label="Erneute Passworteingabe"
                                    id="repeat_password"
                                    type={'password'}
                                    defaultValue={''}
                                    inputRef={register}
                                    error={!!errors.repeat_password}
                                    helperText={
                                        errors.repeat_password && errors.repeat_password.message
                                    }
                                />

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    size={'large'}
                                    className={classes.submit}
                                    disabled={saving}
                                >
                                    Senden
                                </Button>
                            </form>
                        </Box>
                    </div>
                    <Box style={{lineHeight: '30px'}} my={2} pb={3}>
                        Bei Fragen wenden Sie sich gerne an: <Link
                        style={{color: '#28a4ff'}}
                        href="mailto:zeitmanagement@fielmann.com">zeitmanagement@fielmann.com</Link><br/>
                        Mit freundlichen Grüßen,<br/>
                        Ihr Team Zeitmanagement
                    </Box>
                </Container>

            </ThemeProvider>
        </>
    );
}

export default function App() {
    return <Router>
        <Switch>
            <Route path="/setpassword/:token">
                <ResetPassword/>
            </Route>
            <Route>
                <div>Page not found</div>
            </Route>
        </Switch>
    </Router>;
}
